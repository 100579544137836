import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, of, tap } from "rxjs";
import { SessionApiService } from "../../services/api/session-api.service";
import { Session, SessionBase, SessionDetails } from "../../models/sessions/session.model";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private store: StoreService,
    private sessionApiService: SessionApiService
  ) { }

  get sessions$(): Observable<Session[]> {
    return this.store.state$.pipe(map(state => Object.values(state.sessions)));
  }

  load(): Observable<Session[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.sessionApiService.getAll().pipe(
      tap((sessions) => {
        const state = { ...this.store.state };
        sessions.forEach((s) => state.sessions[s.id] = s);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading building sessions:', error);
        throw error;
      })
    );
  }

  loadDetails(id: number, forceRefresh: boolean = false): Observable<SessionDetails> {
    const existingSession = this.store.state.sessions[id];
    if (existingSession && 'blocks' in existingSession && !forceRefresh) {
      return of(existingSession as SessionDetails);
    }

    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.get(id)).pipe(
      tap((session) => {
        this.updateState(session);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading Session details:', error);
        throw error;
      })
    );
  }

  addSession(sessionTemplateId: number, voiceId: number, localeId: number, targetUserId?: string): Observable<Session> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.add(sessionTemplateId, voiceId, localeId, targetUserId)).pipe(
      tap((newSession) => {
        this.updateState(newSession);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding Session:', error);
        throw error;
      })
    );
  }

  updateSessionStoreStatus(id: number, storeStatus: string): Observable<SessionDetails> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.updateStoreStatus(id, storeStatus)).pipe(
      tap((session) => {
        this.updateState(session);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating session store status:', error);
        throw error;
      })
    )
  }

  updateSession(id: number, voiceId:number): Observable<SessionDetails> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.update(id, voiceId)).pipe(
      tap((session) => {
        this.updateState(session);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating session, voiceId:', error);
        throw error;
      })
    )
  }


  updateBlockScript(id: number, blockId: number, script: string): Observable<SessionDetails> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.updateBlockScript(id, blockId, script)).pipe(
      tap((session) => {
        this.updateState(session);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating session block script:', error);
        throw error;
      })
    )
  }

  private updateState(session: SessionDetails | Session) {
    const state = { ...this.store.state };
    state.sessions[session.id] = session;
    state.loading = false;
    this.store.setState(state);
  }

  deleteSession(id: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.sessionApiService.delete(id)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.sessions[id];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting Session:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.sessions = {};
    this.store.setState(state);
  }
}